<template>
  <div class="container">
    <PageMainHeading title="Rates">
      <div class="d-flex w-100 justify-content-end page-heading-toolbar">
        <div class="page-heading-toolbar--col">
          <ShadowControl
            class="search-control"
            icon="search"
            placeholder="Search..."
            name="accounts-search"
          />
        </div>
        <div class="page-heading-toolbar--col">
          <FilterBtn :count="3" />
        </div>
      </div>
    </PageMainHeading>
    <div class="account-wrapper pb-5" style="max-width: 100%; overflow: auto">
      <TableView class="accordion-table inline-table ">
        <template #table-head>
          <TableHeadCell name="Country" sort="a" size="xl" />
          <TableHeadCell name="Calls" size="xs" />
          <TableHeadCell name="SMS" size="xs" />
          <TableHeadCell name="MMS" size="xs" />
          <TableHeadCell name="Fax" size="xs" />

          <TableHeadCell name="Date Updated" size="lg" sort="a" />
          <TableHeadCell name="Updated by" size="md" sort="a" />
        </template>

        <template #table-body>
          <RatesTableRow v-for="n of 10" :key="n" />
        </template>

        <template #table-footer>
          <TableFooter
            :show-count="10"
            :use-select="true"
            count-text="6 of 17 transactions"
            :pagination="{
              current: 1,
              total: 10
            }"
          />
        </template>
      </TableView>
    </div>
  </div>
</template>

<script>
import PageMainHeading from "@/components/Layout/PageMainHeading";
import ShadowControl from "@/components/Form/ShadowControl";
import FilterBtn from "@/components/ui/Buttons/FilterBtn";
import TableHeadCell from "@/components/Table/TableHeadCell";
import TableView from "@/components/Table/TableView";
import RatesTableRow from "@/components/Packages/Rates/RatesTableRow";
import TableFooter from "@/components/Table/TableFooter";
export default {
  name: "Rates",
  components: {
    TableFooter,
    RatesTableRow,
    TableView,
    TableHeadCell,
    FilterBtn,
    ShadowControl,
    PageMainHeading
  }
};
</script>

<style scoped></style>
